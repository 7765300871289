<template>
  <div class="home" :class="{'hideEls': projectImg !== null}">
    <div class="content container-text" ref="content">
      <article class="manifeste mbLg js-opacity">
        <p v-text="home.manifeste" ref="manifeste"></p>
      </article>
      <v-projects-list ref="projectList" class="mbLg" />
      <v-clients class="mbLg" />
      <v-contacts :contacts="home.contacts" class="js-opacity mbMed" />
      <div>&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import transform from "dom-transform";
import vClients from "@/components/home/Clients.vue";
import vProjectsList from "@/components/home/ProjectsList.vue";
import vContacts from "@/components/home/Contacts.vue";

import anime from "animejs/lib/anime.es.js";
export default {
  name: "home",
  computed: {
    ...mapState(["home"]),
    ...mapGetters(["projectImg"])
  },
  components: {
    vProjectsList,
    vClients,
    vContacts
  },
  methods: {
    resize(w, h) {
      if (w && h) {
        this.w = w;
        this.h = h;
      }
      this.$refs.projectList.resize(w, h);
    },
    tick(scrollTop, diffScroll) {
      const absDiff = Math.abs(diffScroll);
      //if (!this.isDevice) transform(this.$refs.content, { skewY: diffScroll });
      this.$refs.projectList.tick(scrollTop);
    }
  },
  mounted() {
    anime({
      targets: this.$refs.manifeste,
      skewY: [5, 0],
      translateY: [200, 0],
      opacity: [0, 1],
      duration: 1000,
      delay: 100,
      easing: "easeOutQuad"
    });
  }
};
</script>
<style lang="stylus" scoped>
@import './../styles/variables.styl';

.home {
  .content {
    position: relative;
    will-change: transform;

    .manifeste {
      position: relative;
      width: 100%;
      padding-top: 200px;

      p {
        @extend .fzLarge;
        margin-bottom: -20px;
      }

      @media screen and (max-width: 1700px) {
        padding-top: 200px;
      }

      @media screen and (max-width: 639px) {
        padding-top: 100px;
      }
    }
  }
}
</style>
<style lang="stylus" >
.home {
  strong.title {
    display: inline-block;
  }
}
</style>

<template>
  <div class="quote" :class="{'show': shown}">
    <q v-html="quote.quote" class="mbSml"></q>
    <strong v-text="quote.author"></strong>
  </div>
</template>

<script>
import ItemMixin from "@/components/project/ItemMixin.vue";
export default {
  mixins: [ItemMixin],
  props: ["quote"]
};
</script>

<style lang="stylus" scoped>
@import './../../styles/variables.styl';

.quote {
  position: relative;
  text-align: center;
  width: 54%;
  margin: -16px auto 0;

  q, strong {
    @extend .fzMedBig;
    display: block;

    &:before, &:after {
      content: '';
    }
  }

  strong {
    text-transform: uppercase;
    display: block;
    margin-top: -33px;
    // margin-top 10vh
    font-weight: 500;
  }

  @media screen and (max-width: 639px) {
    width: 80%;
  }
}
</style>

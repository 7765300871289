import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

//import './registerServiceWorker'
import { sync } from "vuex-router-sync"

const unsync = sync(store, router)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
if (document.querySelector(".no-js-content")) {
  document.querySelector(".no-js-content").style.display = "none"
  document.querySelector(".loader").style.display = "none"
}

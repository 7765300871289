<template>
  <li class="client">
    <router-link
      :to="{ name: 'project', params: {'slug': slug}}"
      v-if="client.projet"
      class="js-opacity project"
      :class="{'no-opacity': projectImg === project.slug}"
      @mouseenter.native="onEnter($event)"
      @mouseleave.native="setProjectImg(null)"
    >
      <span class="name" v-text="client.title"></span>
      <span v-html="client.svg"></span>
    </router-link>
    <div class="js-opacity" v-html="client.svg" v-else></div>
  </li>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  props: ["client"],
  computed: {
    ...mapState(["home", "fontColor"]),
    ...mapGetters(["projectImg", "getProjectBySlug"]),
    slug() {
      return this.client.projet.split("?projets=")[1];
    },
    project() {
      return this.getProjectBySlug(this.slug);
    }
  },
  methods: {
    ...mapActions(["setProjectImg"]),
    onEnter(ev) {
      this.setProjectImg(this.project.slug);
    }
  },
  watch: {
    fontColor(val) {
      this.$el.querySelector("svg").style.fill = val;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.querySelector("svg").style.fill = this.home.fontcolor;
    });
  }
};
</script>

<style lang="stylus" scoped>
.client {
  width: 20%;
  height: 150px;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 400px;

  @media screen and (max-width: 1920px) {
    width: 20%;
  }

  @media screen and (max-width: 1600px) {
    width: 25%;
  }

  @media screen and (max-width: 1279px) {
    width: 33.3333%;
  }

  @media screen and (max-width: 1023px) {
    width: 50%;
  }

  @media screen and (max-width: 639px) {
    width: 100%;
    margin: 0 auto;
  }

  a, div {
    position: relative;
    display: block;
  }

  .name {
    position: absolute;
    left: -50000px;
  }
}
</style>
<style lang="stylus" scoped>
.client {
  >div {
    transform: translateY(-25%);
  }
}
</style>


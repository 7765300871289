<template>
  <article class="contacts">
    <v-title title="Contact" />
    <v-contact v-for="(contact,i) in contacts" :key="`contact-${i}`" :contact="contact" />
  </article>
</template>

<script>
import vTitle from "@/components/Title.vue";
import vContact from "@/components/home/Contact.vue";
export default {
  props: ["contacts"],
  components: {
    vContact,
    vTitle
  }
};
</script>

<style lang="stylus" scoped>
@import './../../styles/variables.styl';

.contacts {
  margin-top: -50px;
}

h3 {
  @extend .fzLarge;
  font-weight: 500;
}
</style>

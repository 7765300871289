<template>
  <article class="projects">
    <v-title title="Projects" class="js-opacity" />
    <div class="list" @mouseleave="mouseLeave">
      <v-project-link
        v-for="(project,i) in latestProject"
        :key="`project-${i}`"
        :project="project"
        ref="projectLinks"
        class="mbTiny"
      />
    </div>
  </article>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vTitle from "@/components/Title.vue";
import vProjectLink from "@/components/home/ProjectLink.vue";
export default {
  components: {
    vTitle,
    vProjectLink
  },
  computed: {
    ...mapGetters(["latestProject"])
  },
  methods: {
    ...mapActions(["resetProjectImg"]),
    mouseLeave() {
      this.resetProjectImg();
    },
    resize(w, h) {
      if (w && h) {
        this.w = w;
        this.h = h;
      }
      this.$refs.projectLinks.forEach(el => {
        el.resize(w, h);
      });
    },
    tick(scrollTop) {
      this.$refs.projectLinks.forEach(el => {
        el.tick(scrollTop);
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.list {
  margin-top: -20px;
  margin-bottom: -30px;
}
</style>

import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    datas: window.Params.datas,
    home: window.Params.datas.pages.home,
    projects: window.Params.datas.pages.projects,
    clients: window.Params.datas.pages.clients,
    currentProjectImg: null,
    fontColor: window.Params.datas.pages.home.fontcolor,
    backgroundColor: null,
    webp:
      document
        .createElement("canvas")
        .toDataURL("image/webp")
        .indexOf("data:image/webp") == 0,
    cloudinary: "https://res.cloudinary.com/drj7uxzqt/"
  },
  mutations: {
    SET_PROJECT_IMG(state, slug) {
      state.currentProjectImg = slug
    },
    RESET_PROJECT_IMG(state, { getters }) {
      state.currentProjectImg = state.route.params.slug
        ? state.route.params.slug
        : null
    },
    SET_WEBP(state, bool) {
      state.webp = bool
    },
    SET_COLORS(state, project) {
      state.backgroundColor = project.bgColor
      state.fontColor = project.fontColor
    },
    RESET_COLORS(state, { getters }) {
      state.backgroundColor = getters.project
        ? getters.project.bgColor
        : state.home.bgcolor
      state.fontColor = getters.project
        ? getters.project.fontColor
        : state.home.fontcolor
    }
  },
  actions: {
    setProjectImg({ commit }, slug) {
      commit("SET_PROJECT_IMG", slug)
    },
    resetProjectImg({ commit, getters }) {
      commit("RESET_PROJECT_IMG", { getters })
      commit("RESET_COLORS", { getters })
    },
    setWebp({ commit }, bool) {
      commit("SET_WEBP", bool)
    },
    setColors({ commit }, project) {
      commit("SET_COLORS", project)
    },
    resetColors({ commit, getters }) {
      commit("RESET_COLORS", { getters })
    }
  },
  getters: {
    latestProject: state => {
      return state.projects.filter(el => {
        return el.show_project_on_home === "1"
      })
    },
    project: state => {
      return state.projects.find(el => {
        return el.slug === state.route.params.slug
      })
    },
    lastestProjectId: (state, getters) => slug => {
      return getters.latestProject.indexOf(getters.getProjectBySlug(slug))
    },
    nextProject: (state, getters) => {
      const projectId = getters.lastestProjectId()
      if (projectId < getters.latestProject.length - 1) {
        return getters.latestProject[projectId + 1]
      } else {
        return getters.latestProject[0]
      }
    },
    getProjectBySlug: (state, getters) => slug => {
      const s = slug ? slug : state.route.params.slug
      return state.projects.find(el => {
        return el.slug === s
      })
    },
    isDevice: () => {
      return "ontouchstart" in window
    },
    projectImg: (state, getters) => {
      return state.currentProjectImg
        ? state.currentProjectImg
        : state.route.params.slug
        ? state.route.params.slug
        : null
    }
  }
})

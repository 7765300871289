<template>
  <div
    id="app"
    :class="{'no-touch': !isDevice, ready}"
    :style="{ backgroundColor, color: fontColor} "
  >
    <v-mouse ref="mouse" v-if="!isDevice" :color="fontColor" />
    <v-scroller ref="scroller" :color="fontColor" />
    <v-header ref="header" />
    <v-transition ref="transition" :backgroundColor="transitionBgColor" />
    <div class="back" ref="back" v-if="$route.name !== 'home'">
      <router-link :to="{'name': 'home'}" class="js-opacity">
        <span>Back</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.91 9.96">
          <path :fill="fontColor" d="M0 5.01L4.81 0l2.1 2.15-2.95 2.86 2.95 3.05-2 1.9z" />
        </svg>
      </router-link>
    </div>
    <div class="scroll" ref="scroll">
      <div ref="pageContainer">
        <router-view ref="page" class="page" :key="$route.path + ($route.params.slug || '')" />
      </div>
    </div>
  </div>
</template>
<script>
import Emitter from "@/assets/js/utils/EventsEmitter";
import ResizeHelper from "@/assets/js/utils/ResizeHelper";
import ScrollHelper from "@/assets/js/utils/ScrollHelper";
import MouseHelper from "@/assets/js/utils/MouseHelper";
import vHeader from "@/components/Header";
import vTransition from "@/components/Transition";
import vMouse from "@/components/Mouse.vue";
import vScroller from "@/components/Scroller.vue";
import transform from "dom-transform";
import { mapState, mapGetters, mapActions } from "vuex";
import loop from "raf-loop";
//import Stats from 'stats-js'
import anime from "animejs/lib/anime.es.js";
export default {
  data() {
    return {
      appInit: false,
      ready: false,
      goBackHome: true,
      transitionBgColor: "#ffffff"
    };
  },
  components: {
    vHeader,
    vMouse,
    vScroller,
    vTransition
  },
  computed: {
    ...mapState(["home", "backgroundColor", "fontColor"]),
    ...mapGetters(["isDevice", "project", "getProjectBySlug"])
  },
  methods: {
    ...mapActions(["setProjectImg", "resetColors"]),
    resize() {
      const w = ResizeHelper.width();
      const h = ResizeHelper.height();
      this.$nextTick(() => {
        const pageHeight = this.$refs.pageContainer.clientHeight;
        if (this.$refs.page)
          this.$refs.page.resize && this.$refs.page.resize(w, h, pageHeight);
        if (!this.isDevice) document.body.style.height = pageHeight + "px";
        this.$refs.scroller.resize(w, h, pageHeight);
        this.$refs.header.resize(w, h);
      });
    },
    tick() {
      if (this.stats) this.stats.begin();
      ScrollHelper.tick();
      const scrollTop = this.isDevice
        ? ScrollHelper.scrollTop
        : ScrollHelper.ease;
      const easeY = this.isDevice ? ScrollHelper.ease : ScrollHelper.easeSlow;
      if (this.$refs.page)
        this.$refs.page.tick &&
          this.$refs.page.tick(scrollTop, ScrollHelper.diffScroll);
      if (!this.isDevice) {
        transform(this.$refs.scroll, {
          translate3d: [0, -scrollTop, 0],
          skewY: ScrollHelper.diffScroll
        });
        MouseHelper.tick();
      } else {
        transform(this.$refs.scroll, {
          skewY: ScrollHelper.diffScroll * 2
        });
      }
      if (this.$refs.mouse) this.$refs.mouse.tick(scrollTop);
      this.$refs.scroller.tick(scrollTop);
      if (this.stats) this.stats.end();
    },
    setDebug() {
      this.stats = new Stats();
      document.body.appendChild(this.stats.domElement);
      this.stats.domElement.style.position = "fixed";
      this.stats.domElement.style.zIndex = 100;
    }
  },
  watch: {
    backgroundColor(val) {
      document.body.style.background = val;
    }
  },
  mounted() {
    Emitter.on("GLOBAL:RESIZE", this.resize.bind(this));
    this.$nextTick(() => {
      this.resize();
    });
    const engine = loop(this.tick.bind(this)).start();
    this.$router.beforeEach((to, from, next) => {
      if (this.$refs.mouse) this.$refs.mouse.reset();
      this.goBackHome = true;
      this.$refs.scroller.doTransition(250);
      if (to.name === "home") {
        this.$refs.transition.show();
        this.transitionBgColor = this.home.bgcolor;
      } else {
        this.transitionBgColor = this.getProjectBySlug(to.params.slug).bgColor;
      }
      this.animation = anime({
        targets: this.$refs.scroll,
        opacity: 0,
        duration: 1000,
        easing: "easeInOutQuad",
        complete: next
      });
    });

    document.body.style.background = this.backgroundColor;
    this.$router.afterEach((to, from) => {
      this.resetColors();
      this.goBackHome = false;
      ScrollHelper.goTo(0);
      ScrollHelper.ease = 0;
      if (this.$refs.mouse) this.$refs.mouse.changePage();
      this.$nextTick(this.resize.bind(this));
      if (to.name === "home") {
        this.setProjectImg(null);
      }
      setTimeout(() => {
        this.$refs.transition.hide();
        this.animation = anime({
          targets: this.$refs.scroll,
          opacity: [0, 1],
          translateX: ["0%", "0%"],
          duration: 500,
          easing: "easeInOutQuad"
        });
      }, 200);
      // to and from are both route objects.
    });
    //this.setDebug()
    this.resetColors();
    if (this.$refs.mouse) this.$refs.mouse.changePage();
    setTimeout(() => {
      this.ready = true;
    }, 1000);
    this.transitionBgColor = this.home.bgcolor;
  }
};
</script>
<style lang="stylus">
@import 'styles/*';

#app {
  font-family: gotham;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  overscroll-behavior: none;
  @extend .fzSml;
  background: black;
  min-height: 100%;
  overflow: hidden;

  &.ready {
    transition: background 0.5s, color 0.5s;
  }

  .back {
    position: fixed;
    left: 50%;
    top: 80px;
    transform: translateX(-820px);
    z-index: 8;

    svg {
      display: none;
    }

    @media screen and (max-width: 1920px) {
      left: 80px;
      transform: translateX(0);
    }

    @media screen and (max-width: 640px) {
      top: 20px;
      left: 0px;
      padding: 20px;

      span {
        display: none;
      }

      svg {
        display: block;
        width: 12px;
        height: 15px;
      }
    }

    a {
      color: inherit;
      @extend .fzSml;
    }
  }

  .scroll {
    position: relative;
  }

  &.no-touch .scroll {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    will-change: transform;
  }
}

.js-opacity {
  opacity: 1;
  transition: opacity 0.5s ease-out-quad;
}

.hideEls .js-opacity {
  opacity: 0.5;
}

.hideEls .js-opacity.no-opacity {
  opacity: 1;
  z-index: 2;
}

.js-opacity {
  opacity: 1;
  transition: opacity 0.5s ease-out-quad;
}

.project.hideEls .js-opacity {
  opacity: 0;
  transition: opacity 0.5s ease-out-quad;
}

.project.hideEls a.js-opacity {
  opacity: 0.5;
  transition: opacity 0.5s ease-out-quad;
}

.project.hideEls .js-opacity.no-opacity {
  opacity: 1;
}
</style>

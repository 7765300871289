<template>
  <header :class="{ isLarge }">
    <router-link :to="{ name: 'home' }">
      <span>manifesto</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="MANIFESTO"
        viewBox="0 0 1637.56 261.87"
        v-if="isLarge"
      >
        <path
          id="M_2_"
          d="M214 10.93v-5h-53.85l-52.29 190.11L55.54 5.93H0v5h23v241H0v5h51v-5H28V12.01l67.21 244.91L163 11.93v240h-31v5h82v-5h-23v-241z"
          fill="#6f070f"
        />
        <path
          id="A_2_"
          d="M226.24 251.68h16.58L318.75 0h6.75l76.49 251.68h16.86v4.92h-70.27v-4.92h23.05l-29.53-97.72h-64.52l-29.53 97.72h22.33v4.92h-44.12v-4.92zm83.23-204.93l-30.93 102.29h61.86L309.47 46.75z"
          fill="#6f070f"
        />
        <path
          id="N_2_"
          d="M604 5.93h-48v5h21v187h-.39l-95.05-192H430v5h22v241h-22v5h50v-5h-23v-218l4.46.02 111.89 222.98H582v-246h22z"
          fill="#6f070f"
        />
        <path
          id="I_2_"
          d="M624.7 252.03h23.05V10.86H624.7V5.94h74.21v4.92h-23.05v241.17h23.05v4.92H624.7v-4.92z"
          fill="#6f070f"
        />
        <path
          id="F_2_"
          d="M723.34 252.02h21.41V10.86h-21.41V5.94h151.05v60.81h-3.09c-2.44-9.14-5.06-17.16-7.87-24.08-2.81-6.91-6.19-12.77-10.12-17.57-3.94-4.8-8.62-8.38-14.06-10.72-5.44-2.34-12-3.52-19.68-3.52h-46.68v241.16h23.05v4.92h-72.58v-4.92z"
          fill="#6f070f"
        />
        <path
          d="M825.79 160.41c1.41 6.33 2.77 13.53 4.08 19.53H833v-36h-13c2.48 4.99 4.41 10.24 5.79 16.47z"
          fill="#6f070f"
        />
        <path
          id="F_x2F__3_"
          d="M825.79 102.46c1.41-6.33 2.77-13.53 4.08-19.53H833v36h-13c2.47-4.86 4.41-10.24 5.79-16.47z"
          fill="#6f070f"
        />
        <path
          d="M1001.79 160.41c1.41 6.33 2.77 13.53 4.08 19.53h3.13v-36h-13c2.48 4.99 4.41 10.24 5.79 16.47z"
          fill="#6f070f"
        />
        <path
          id="F_x2F__2_"
          d="M1001.79 102.46c1.41-6.33 2.77-13.53 4.08-19.53h3.13v36h-13c2.47-4.86 4.41-10.24 5.79-16.47z"
          fill="#6f070f"
        />
        <path
          id="E_5_"
          d="M1051 5.93v61h-2.96c-2.44-9-5.06-17.1-7.87-24.02-2.81-6.91-6.19-12.71-10.12-17.51-3.94-4.8-8.62-8.45-14.06-10.8-5.44-2.34-12-3.67-19.68-3.67H950v241h46.31c7.68 0 14.24-1.33 19.68-3.67 5.44-2.35 10.12-6 14.06-10.8 3.94-4.81 7.31-10.6 10.12-17.51 2.81-6.92 5.44-15.02 7.87-24.02h2.96v61H900v-5h22v-241h-22v-5h151z"
          fill="#6f070f"
        />
        <path
          id="S_2_"
          d="M1089.22 181.02h3.09c2.06 12.65 4.73 23.32 8.01 31.99 3.28 8.67 6.89 15.82 10.83 21.44 3.94 5.62 8.06 9.96 12.37 13.01 4.31 3.05 8.67 5.27 13.08 6.68 4.4 1.41 8.62 2.23 12.65 2.46 4.03.23 7.83.35 11.39.35 9.93 0 18.09-1.87 24.46-5.62 6.37-3.75 11.43-8.49 15.18-14.24a56.37 56.37 0 0 0 7.87-18.63c1.5-6.68 2.25-12.83 2.25-18.45 0-8.2-1.22-15.23-3.66-21.09-2.44-5.85-5.53-10.84-9.28-14.94-3.75-4.1-7.83-7.44-12.23-10.02-4.41-2.58-8.58-4.57-12.51-5.98l-32.34-12.3c-15.18-5.86-26.67-13.59-34.45-23.2-7.78-9.61-11.67-22.5-11.67-38.66 0-10.54 1.5-20.33 4.5-29.35 3-9.02 7.21-16.81 12.65-23.38 5.44-6.56 11.95-11.71 19.54-15.47 7.59-3.75 15.89-5.62 24.88-5.62 6.56 0 12.04.7 16.45 2.11 4.4 1.41 8.2 2.99 11.39 4.75 3.19 1.76 6.05 3.34 8.58 4.75 2.53 1.41 5.11 2.11 7.73 2.11 2.43 0 4.08-.41 4.92-1.23.84-.82 2.01-2.87 3.52-6.15h3.94v63.62h-3.38c-1.13-8.44-3.05-16.58-5.77-24.43-2.72-7.85-6.23-14.76-10.54-20.74-4.32-5.98-9.52-10.78-15.61-14.41-6.09-3.63-13.17-5.45-21.23-5.45-4.5 0-9.47.76-14.9 2.28-5.44 1.53-10.5 4.16-15.19 7.91-4.69 3.75-8.58 8.67-11.67 14.76-3.09 6.1-4.64 13.83-4.64 23.2 0 7.5 1.22 13.88 3.66 19.16 2.43 5.27 5.57 9.73 9.42 13.36 3.84 3.64 8.15 6.68 12.93 9.14 4.78 2.46 9.61 4.63 14.48 6.5l34.03 13.36c3.56 1.41 8.06 3.28 13.5 5.62 5.44 2.35 10.73 5.86 15.89 10.55 5.15 4.69 9.56 10.9 13.22 18.63 3.65 7.73 5.48 17.58 5.48 29.53 0 11.02-1.64 21.5-4.92 31.46-3.28 9.96-8.01 18.75-14.2 26.36-6.19 7.62-13.69 13.71-22.5 18.28-8.82 4.57-18.75 6.85-29.81 6.85-3.94 0-7.41-.06-10.41-.17-3-.12-5.81-.41-8.44-.88-2.62-.47-5.25-1-7.87-1.58-2.63-.59-5.53-1.47-8.72-2.64-3.75-1.41-7.78-3.04-12.09-4.92-4.32-1.87-8.06-2.81-11.25-2.81-2.81 0-4.73.7-5.77 2.11-1.03 1.41-2.02 3.62-2.77 5.96l-4.12.02v-75.95z"
          fill="#6f070f"
        />
        <path
          id="T_3_"
          d="M1303.51 252l23.05.02V10.87h-16.31c-3.75 0-7.97.41-12.66 1.23-4.69.82-9.42 3.11-14.2 6.85-4.78 3.75-9.38 9.67-13.78 17.75-4.41 8.08-8.2 19.28-11.39 33.57h-3.09V5.95h170.97v64.32h-3.09c-3.19-14.29-6.99-25.48-11.39-33.57-4.41-8.08-9-14-13.78-17.75-4.78-3.75-9.52-6.03-14.2-6.85-4.69-.82-8.9-1.23-12.65-1.23h-16.31v241.15h23.05v4.92h-74.21V252z"
          fill="#6f070f"
        />
        <path
          id="O_2_"
          d="M1441.84 131.11c0-18.75 2.95-36.08 8.86-52.02 5.91-15.93 13.5-29.76 22.78-41.48 9.28-11.71 19.73-20.91 31.35-27.59C1516.46 3.34 1528.08 0 1539.7 0c12.38 0 24.42 3.34 36.13 10.02s22.17 15.88 31.35 27.59c9.19 11.72 16.54 25.54 22.07 41.48 5.53 15.94 8.29 33.28 8.29 52.02 0 16.4-2.53 32.46-7.59 48.15-5.06 15.7-12 29.65-20.81 41.83-8.82 12.19-19.17 22.03-31.07 29.53-11.91 7.5-24.7 11.25-38.39 11.25-14.06 0-27.09-3.63-39.08-10.9-12-7.26-22.35-16.93-31.07-29-8.72-12.07-15.51-26.01-20.39-41.83-4.86-15.81-7.3-32.16-7.3-49.03zm32.35 0c0 21.09 1.12 39.49 3.37 55.19 2.25 15.7 6 28.82 11.25 39.37 5.25 10.55 12 18.4 20.25 23.55 8.25 5.16 18.46 7.73 30.65 7.73 12 0 22.17-2.57 30.51-7.73 8.34-5.15 15.14-13.01 20.39-23.55 5.25-10.55 9-23.67 11.25-39.37 2.25-15.7 3.38-34.1 3.38-55.19 0-16.17-.84-31.81-2.53-46.93-1.69-15.12-4.92-28.59-9.7-40.42-4.78-11.83-11.44-21.27-19.96-28.3-8.53-7.03-19.64-10.54-33.32-10.54-13.69 0-24.79 3.51-33.32 10.54-8.53 7.03-15.19 16.47-19.97 28.3-4.78 11.84-8.01 25.31-9.7 40.42-1.71 15.12-2.55 30.76-2.55 46.93z"
          fill="#6f070f"
        />
        <path
          id="M_1_"
          d="M30.55 32.63h40.19l44.55 83.67 44.55-83.67h40.19v197.89h-37.01v-129.2l-48.4 85.4-47.5-84.55v128.35H30.55V32.63z"
        />
        <path
          id="A_1_"
          d="M306.92 31.22h34.38l72.63 199.3h-38.98l-15.49-44.39h-71.67l-15.49 44.39h-38.01l72.63-199.3zm39.22 116.46l-22.52-64.17-22.52 64.17h45.04z"
        />
        <path
          id="N_1_"
          d="M448.23 32.63h34.38l79.41 121.84V32.63h36.8v197.89H567.1l-82.07-125.8v125.8h-36.8V32.63z"
        />
        <path id="I_1_" d="M659.5 32.63h37.28v197.89H659.5V32.63z" />
        <path
          id="F_1_"
          d="M760.94 33.04h104.3v38.73h-67.26v40.14h67.13v38.73h-67.13l-.04 80.29h-37.01V33.04z"
        />
        <path
          id="E_3_"
          d="M936.64 32.63h104.3v38.73h-67.26v40.14h67.13v38.73h-67.13v41.55h67.47v38.73H936.64V32.63z"
        />
        <path
          id="S_1_"
          d="M1100.12 201.68l22.03-30.81c15.25 14.7 31.23 24.02 50.6 24.02 15.26 0 24.45-7.06 24.45-18.66v-.56c0-11.02-5.81-16.68-34.13-25.16-34.14-10.18-56.17-21.2-56.17-60.49v-.57c0-35.9 24.69-59.65 59.32-59.65 24.7 0 45.75 9.04 62.94 25.16l-19.37 32.79c-15.02-12.16-29.78-19.5-44.06-19.5-14.28 0-21.79 7.63-21.79 17.25v.56c0 13.01 7.26 17.24 36.56 26.01 34.38 10.46 53.74 24.88 53.74 59.37v.56c0 39.3-25.66 61.35-62.22 61.35-25.66-.01-51.56-10.47-71.9-31.67z"
        />
        <path
          id="T_2_"
          d="M1320.4 72.77h-51.57V32.63h140.42v40.14h-51.56v157.75h-37.28V72.77z"
        />
        <path
          id="O_1_"
          d="M1436.57 132.14v-.57c0-56.25 38.01-102.34 90.31-102.34 52.3 0 89.82 45.51 89.82 101.77v.56c0 56.26-38.01 102.34-90.31 102.34-52.29 0-89.82-45.51-89.82-101.76zm141.15 0v-.57c0-33.92-21.3-62.19-51.33-62.19-30.02 0-50.84 27.7-50.84 61.63v.56c0 33.92 21.31 62.2 51.33 62.2s50.84-27.71 50.84-61.63z"
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 214 251" v-else>
        <path
          fill="#56040a"
          d="M160.149 0L107.86 190.108 55.542 0H0v5h23.001v241H0v5h51.001v-5H28V6.079l67.211 244.906L163 6v240h-30.999v5h82v-5H191V5h23.001V0z"
        />
        <path
          d="M30.55 26.693h40.189l44.548 83.674 44.547-83.674h40.19v197.888h-37.01V95.385l-48.405 85.396-47.501-84.547v128.347H30.55z"
        />
      </svg>
    </router-link>
  </header>
</template>

<script>
import { mapState } from "vuex"
export default {
  data() {
    return {
      isLarge: true
    }
  },
  computed: {
    ...mapState(["home"])
  },
  methods: {
    resize(w, h) {
      if (w <= 639) {
        this.isLarge = false
      } else {
        this.isLarge = true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
header {
  position: fixed;
  z-index: 5;
  transform: translate(-50%, 0%);
  left: 50%;
  width: 40px;
  height: 56px;
  top: 20px;

  &.isLarge {
    width: 360px;
    top: 60px;
  }

  a {
    width: 100%;
    height: 100%;
    display: block;

    span {
      position: absolute;
      left: -50000px;
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
</style>

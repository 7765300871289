<template>
  <div class="project" :class="{ hideEls: projectImg !== project.slug }">
    <article class="content" ref="content">
      <div class="skewable" ref="skewable">
        <div class="cover mbLg" ref="coverCont">
          <div ref="cover">
            <img :src="url" alt v-if="project.cover_type === 'image'" />
            <video
              :src="project.cover.url"
              v-if="project.cover_type === 'video'"
              autoplay
              playsinline
              muted
              loop
              preload="auto"
            ></video>
          </div>
        </div>
        <div class="container-text">
          <header class="mbLg" :style="{ color: project ? project.fontColor : '' }">
            <ul class>
              <li class="mbMed">
                <v-title title="Client" :color="project.fontColor" />
                <h1 v-text="project.client_name"></h1>
              </li>
              <li class="mbMed">
                <v-title title="Project Type" :color="project.fontColor" />
                <h2 v-text="project.project_type"></h2>
              </li>
              <li class="mbMed">
                <v-title title="Pitch" :color="project.fontColor" />
                <p v-text="project.pitch"></p>
              </li>
              <li>
                <v-title title="Role" :color="project.fontColor" />
                <div class="roles">
                  <h3 v-for="(role, i) in project.roles" :key="`role-${i}`" v-text="role"></h3>
                </div>
              </li>
            </ul>
          </header>
        </div>
        <div
          v-for="(item, i) in project.content"
          :key="i"
          class="item mbLg"
          :style="getStyle(item)"
        >
          <v-img-large :media="item.image" v-if="item.layout === 'imgLarge'" />
          <v-video-large :media="item.video" v-if="item.layout === 'videoLarge'" />
          <v-img-med :media="item.image" v-if="item.layout === 'imgMed'" :width="item.width" />
          <v-video-med :media="item.video" v-if="item.layout === 'videoMed'" :width="item.width" />
          <v-quote :quote="item.quote" v-if="item.layout === 'quote'" />
        </div>
      </div>
    </article>
    <v-project-link
      :project="nextProject"
      :color="project.fontColor"
      v-if="project.show_project_on_home"
      isNext="true"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import anime from "animejs/lib/anime.es.js";
import transform from "dom-transform";
import ResizeHelper from "@/assets/js/utils/ResizeHelper.js";
import vProjectLink from "@/components/home/ProjectLink.vue";
import vTitle from "@/components/Title.vue";
import vQuote from "@/components/project/Quote.vue";
import vImgMed from "@/components/project/ImgMed.vue";
import vImgLarge from "@/components/project/ImgLarge.vue";
import vVideoMed from "@/components/project/VideoMed.vue";
import vVideoLarge from "@/components/project/VideoLarge.vue";
export default {
  data() {
    return {
      imgArray: [],
      imgLoaded: 0
    };
  },
  components: {
    vProjectLink,
    vTitle,
    vQuote,
    vImgMed,
    vImgLarge,
    vVideoLarge,
    vVideoMed
  },
  computed: {
    ...mapState(["projects", "cloudinary"]),
    ...mapGetters(["project", "nextProject", "projectImg"]),
    url() {
      //return this.src
      let w =
        "f_auto,w_" +
        Math.min(Math.ceil(ResizeHelper.width() / 500) * 500, 1920) +
        "/";
      let url = this.project.cover.url.replace(
        this.cloudinary,
        this.cloudinary + w
      );
      return url;
    }
  },
  methods: {
    getStyle(item) {
      let style = {
        color: this.project ? this.project.fontColor : ""
      };
      return style;
    },
    resize(w, h) {
      if (w && h) {
        this.w = w;
        this.h = h;
      }
      this.$children.forEach(child => {
        child.resize && child.resize(this.w, this.h);
        child.setOffset && child.setOffset();
      });

      if (this.project.cover_type === "video" && this.w > 639) {
        let videoW,
          videoH,
          videoRatio =
            parseInt(this.project.cover.width, 10) /
            parseInt(this.project.cover.height, 10);
        if (this.w / this.h > videoRatio) {
          videoW = this.w;
          videoH = this.w / videoRatio;
        } else {
          videoW = this.h * videoRatio;
          videoH = this.h;
        }
        this.$refs.cover.querySelector("video").style.width = videoW + "px";
        this.$refs.cover.querySelector("video").style.height = videoH + "px";
      }
    },
    tick(scrollTop, diffScroll) {
      const absDiff = Math.abs(diffScroll);
      //if (!this.isDevice) transform(this.$refs.skewable, { skewY: diffScroll });
      //transform(this.$refs.back, { translate3d: [0, scrollTop, 0] });
      this.tick_array.forEach(child => {
        child.tick(scrollTop, this.h);
      });
    },
    onImgLoaded() {
      this.imgLoaded++;
      if (this.imgLoaded === this.imgArray.length)
        setTimeout(() => {
          this.$parent.resize();
        }, 300);
    }
  },
  beforeDestroy() {
    this.imgArray.forEach(element => {
      element.removeEventListener("load", this._onImgLoaded);
    });
  },
  mounted() {
    this.$el.style.opacity = 0;
    setTimeout(() => {
      this.$el.style.opacity = 1;
      anime({
        targets: this.$refs.back,
        opacity: [0, 1],
        duration: 1000,
        easing: "easeInOutQuad"
      });
      anime({
        targets: this.$refs.coverCont,
        translateX: ["-100%", "0%"],
        duration: 750,
        easing: "easeInOutQuad",
        complete: () => {
          this.$refs.coverCont.style = "";
        }
      });
      anime({
        targets: this.$refs.cover,
        translateX: ["90%", "0%"],
        duration: 750,
        easing: "easeInOutQuad",
        complete: () => {
          this.$refs.cover.style = "";
        }
      });
    }, 500);
    this._onImgLoaded = this.onImgLoaded.bind(this);
    this.tick_array = [];
    this.$children.forEach(child => {
      if (child.tick) this.tick_array.push(child);
    });
    this.$nextTick(() => {
      const imgArray = [].slice.call(this.$el.querySelectorAll("img"));
      imgArray.forEach(element => {
        const img = new Image();
        img.addEventListener("load", this._onImgLoaded);
        img.addEventListener("error", this._onImgLoaded);
        img.src = element.src;
        this.imgArray.push(img);
      });
    });
  }
};
</script>

<style lang="stylus" scoped>
@import './../styles/variables.styl';

.project {
  .cover {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    >div {
      height: 100%;
      width: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;

      @media screen and (max-width: 639px) {
        position: relative;
        height: auto;
      }
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
      display: block;

      @media screen and (max-width: 639px) {
        position: relative;
        transform: none;
        top: auto;
        left: auto;
        height: auto;
      }
    }

    @media screen and (max-width: 639px) {
      height: auto;
    }
  }

  header {
    ul {
      width: 100%;
      padding: 0 40px;
      margin: 0 auto;

      @media screen and (max-width: 639px) {
        padding: 0 20px;
      }
    }

    .title {
      display: block;
    }

    h1, h2, h3, p {
      @extend .fzLarge;
      font-weight: 500;
    }

    h3 {
      @extend .fzMedBig;
    }

    h1 {
      text-transform: uppercase;
    }

    p {
      @extend .fzMedBig;
      margin-top: -18px;
      margin-bottom: -20px;
    }

    h1, h2, .roles {
      margin-top: -25px;
      margin-bottom: -30px;
    }
  }

  .skewable {
    will-change: transform;
  }

  .projectLink {
    padding: 0 0 290px;
    margin: 0 auto;
    text-align: center;

    @media screen and (max-width: 639px) {
      padding-bottom: 145px;
    }
  }
}
</style>

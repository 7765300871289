
<template>
  <div class="transition" :style="{background: backgroundColor} "></div>
</template>
<script>
import anime from "animejs";
import { mapState, mapGetters } from "vuex";
export default {
  name: "mouse",
  data() {
    return {};
  },
  computed: {
    // ...mapState(["home", "backgroundColor"]),
    // ...mapGetters(["project"]),
    // bgColor() {
    //   return this.$route.name === "home"
    //     ? this.home.bgcolor
    //     : this.project
    //     ? this.project.bgColor
    //     : "";
    // }
  },
  props: ["backgroundColor"],
  methods: {
    show() {
      this.$el.style.display = "block";
      this.$el.style.opacity = 1;
      anime({
        targets: this.$el,
        translateX: ["-100%", "0%"],
        easing: "easeInOutQuad",
        duration: 1000,
        delay: 0
      });
    },
    hide() {
      anime({
        targets: this.$el,
        easing: "easeInOutQuad",
        translateX: ["0%", "0%"],
        opacity: 0,
        duration: 500,
        complete: () => {
          this.$el.style.display = "none";
        }
      });
    }
  },
  mounted() {
    this.hide();
  }
};
</script>

<style lang="stylus" scoped>
@import './../styles/variables.styl';

.transition {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9;
}
</style>

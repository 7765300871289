<template>
  <div :class="{'show': shown}">
    <img class="imgMed container" :src="url" :alt="media.alt" :style="{'width': thisWidth}" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResizeHelper from "@/assets/js/utils/ResizeHelper";
import ItemMixin from "@/components/project/ItemMixin.vue";
export default {
  mixins: [ItemMixin],
  props: ["media", "width"],
  computed: {
    ...mapState(["webp", "cloudinary"]),
    url() {
      //return this.src
      let w =
        "f_auto,w_" +
        Math.min(Math.ceil(ResizeHelper.width() / 500) * 500, 1920) +
        "/";
      let url = this.media.url.replace(this.cloudinary, this.cloudinary + w);
      //url = this.webp ? url.replace('.jpeg', '.webp').replace('.jpg', '.webp') : url
      return url;
    },
    thisWidth() {
      return this.width ? this.width + "%" : "84%";
    }
  }
};
</script>

<style lang="stylus" scoped>
@import './../../styles/variables.styl';

div {
  // padding 10vh 0
}

.imgMed {
  position: relative;
  display: block;
  height: auto;

  @media screen and (max-width: 1023px) {
    width: calc(100% - 130px) !important;
  }

  @media screen and (max-width: 639px) {
    width: calc(100% - 80px) !important;
  }
}
</style>

<template>
  <figure class="imgLarge" :class="{'show': shown}">
    <img :src="url" :alt="media.alt" />
  </figure>
</template>

<script>
import { mapState } from "vuex";
import ResizeHelper from "@/assets/js/utils/ResizeHelper";
import ItemMixin from "@/components/project/ItemMixin.vue";
export default {
  mixins: [ItemMixin],
  props: ["media"],
  computed: {
    ...mapState(["webp", "cloudinary"]),
    url() {
      //return this.src
      let w = "f_auto,w_" + Math.ceil(ResizeHelper.width() / 500) * 500 + "/";
      let url = this.media.url.replace(this.cloudinary, this.cloudinary + w);
      //url = this.webp ? url.replace('.jpeg', '.webp').replace('.jpg', '.webp') : url
      return url;
    }
  }
};
</script>

<style lang="stylus" scoped>
@import './../../styles/variables.styl';

.imgLarge {
  position: relative;
  width: 100%;
  max-width: 2560px;
  margin: 0 auto;

  img {
    height: auto;
  }
}
</style>


<template>
  <div class="mouse" :class="{'hover': isHover}">
    <div class="circle" :style="{'border-color': color}"></div>
    <span class="indication discover" :class="{'show': isDiscoverHover}" :style="{'color': color}">
      <span class="rel">Discover</span>
      <span class="bottom">
        <span class="inner">Discover</span>
      </span>
      <span class="top">
        <span class="inner">Discover</span>
      </span>
    </span>
    <span class="indication discover" :class="{'show': isScrollHover}" :style="{'color': color}">
      <span class="rel">
        Scroll to
        <br />Navigate
      </span>
      <span class="bottom">
        <span class="inner">
          Scroll to
          <br />Navigate
        </span>
      </span>
      <span class="top">
        <span class="inner">
          Scroll to
          <br />Navigate
        </span>
      </span>
    </span>
    <span class="indication send" :class="{'show': isSendHover}" :style="{'color': color}">
      <span class="rel">Send</span>
      <span class="bottom">
        <span class="inner">Send</span>
      </span>
      <span class="top">
        <span class="inner">Send</span>
      </span>
    </span>
    <span class="indication call" :class="{'show': isCallHover}" :style="{'color': color}">
      <span class="rel">Call</span>
      <span class="bottom">
        <span class="inner">Call</span>
      </span>
      <span class="top">
        <span class="inner">Call</span>
      </span>
    </span>
  </div>
</template>
<script>
import transform from "dom-transform";
import Emitter from "@/assets/js/utils/EventsEmitter";
import MouseHelper from "@/assets/js/utils/MouseHelper";
import { mapState, mapGetters } from "vuex";
export default {
  name: "mouse",
  data() {
    return {
      width: 40,
      height: 40,
      strokeWidth: 5,
      isDiscoverHover: false,
      isSendHover: false,
      isCallHover: false,
      isScrollHover: false,
      isHover: false
    };
  },
  props: ["color"],
  /*computed: {
    ...mapState(["home"]),
    ...mapGetters(["project"]),
    bgColor() {
      return this.$route.name === "home"
        ? this.home.fontcolor
        : this.project
        ? this.project.fontColor
        : "";
    }
  },*/
  methods: {
    tick(scrollTop) {
      this.scrollTop = scrollTop;
      if (this.isScrollHover && scrollTop > 10) this.onMouseOut();
      transform(this.$el, { translate3d: [MouseHelper.x, MouseHelper.y, 0] });
    },
    onMouseOver(e) {
      this.isDiscoverHover = false;
      this.isScrollHover = false;
      this.isSendHover = false;
      this.isCallHover = false;

      if (e.currentTarget.className.indexOf("call") !== -1)
        this.isCallHover = true;
      if (e.currentTarget.className.indexOf("send") !== -1)
        this.isSendHover = true;
      if (e.currentTarget.className.indexOf("contactLink") !== -1)
        this.isDiscoverHover = true;
      if (e.currentTarget.className.indexOf("project") !== -1)
        this.isDiscoverHover = true;
      if (
        e.currentTarget.className.indexOf("padding") !== -1 &&
        this.scrollTop < 10
      )
        this.isScrollHover = true;
      this.isHover = true;
      if (
        e.currentTarget.className.indexOf("padding") !== -1 &&
        this.scrollTop >= 10
      )
        this.isHover = false;
    },
    onMouseOut() {
      this.isDiscoverHover = false;
      this.isHover = false;
      this.isScrollHover = false;
      this.isCallHover = false;
      this.isSendHover = false;
    },
    onMouseClick() {
      if (this.isScrollHover) Emitter.emit("WINDOW:DOSCROLL");
      this.isClicked = true;
      this.onMouseOut();
      setTimeout(() => {
        this.isClicked = false;
      }, 100);
    },
    reset() {
      this.isHover = false;
      this.links_array.forEach(element => {
        element.removeEventListener("mouseover", this._onMouseOver);
        element.removeEventListener("mouseout", this._onMouseOut);
      });
      window.removeEventListener("mousedown", this._onMouseClick);
    },
    changePage() {
      this.$nextTick(() => {
        this.links_array = [].slice.call(
          document.querySelectorAll("a, .padding")
        );
        this.links_array.forEach(element => {
          element.addEventListener("mouseover", this._onMouseOver);
          element.addEventListener("mouseout", this._onMouseOut);
        });
        window.addEventListener("mousedown", this._onMouseClick);
      });
    }
  },
  mounted() {
    this._onMouseOver = this.onMouseOver.bind(this);
    this._onMouseOut = this.onMouseOut.bind(this);
    this._onMouseClick = this.onMouseClick.bind(this);

    this.changePage();
  }
};
</script>

<style lang="stylus" scoped>
@import './../styles/variables.styl';

.mouse {
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  z-index: 20;

  .circle {
    border-radius: 50%;
    overflow: hidden;
    height: 50px;
    width: 50px;
    transform: translate(-50%, -50%);
    transition: width 0.3s ease-out-quad, height 0.3s ease-out-quad, opacity 0.3s ease-out-quad;
    will-change: width, height;
    border: 2px solid green;
  }

  .indication {
    text-align: center;
    position: absolute;
    font-size: 14px;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    color: green;

    &.show {
      .top .inner {
        transform: translateY(0%);
      }

      .bottom .inner {
        transform: translateY(-50%);
      }

      .inner {
        transition: transform 0.5s ease-out-quad 0.2s;
      }
    }

    span {
      display: block;
      line-height: 1;
    }

    .rel {
      position: relative;
      opacity: 0;
    }

    .inner {
      transition: transform 0.2s ease-in-quad;
      will-change: transform;
    }

    .top {
      top: 0;
      height: 50%;
      overflow: hidden;
      position: absolute;

      .inner {
        transform: translateY(50%);
      }
    }

    .bottom {
      bottom: 0;
      height: 50%;
      overflow: hidden;
      position: absolute;

      .inner {
        transform: translateY(-100%);
      }
    }
  }

  &.hover .circle {
    opacity: 1;
    width: 94px;
    height: 94px;
    /* &:before
    transform scale(1.2, 1.2) */
  }
}
</style>

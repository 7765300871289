<template>
  <div class="contact mbTiny">
    <h4>
      <span v-text="contact.nom" class="nom"></span>
      <span class="bar" :style="{'background': this.home.fontcolor}"></span>
      <span class="position" v-text="contact.position"></span>
    </h4>
    <a
      :href="`mailto:${contact.mail}`"
      v-text="contact.mail"
      class="send"
      target="_blank"
      rel="noopener"
    ></a>
    <br />
    <a :href="`tel:${phone}`" v-text="contact.phone" class="call" target="_blank" rel="noopener"></a>
    <br />
    <div v-for="(link, i) in contact.links" :key="`link-${i}`">
      <a :href="link.link" v-text="link.label" class="contactLink" target="_blank" rel="noopener"></a>
      <br />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["contact"],
  computed: {
    ...mapState(["home"]),
    phone() {
      return this.contact.phone
        .replace("(0)", "")
        .replace(new RegExp(" ", "g"), "");
    }
  }
};
</script>

<style lang="stylus" scoped>
@import './../../styles/variables.styl';

.contact {
  display: block;

  h4, a {
    display: block;
    font-weight: 500;
  }

  h4 {
    margin-bottom: -10px;
  }

  a {
    color: inherit;
    display: inline-block;
    @extend .fzReg;
  }

  .position {
    @extend .fzRegBig;
  }

  .nom {
    @extend .fzMed;
  }

  .bar {
    margin-left: 1.9vw;
  }

  span {
    vertical-align: baseline;
    text-transform: uppercase;
  }

  @media screen and (max-width: 1024px) {
    span {
      display: block;
    }

    h4 {
      margin-bottom: -4px;
    }

    .position {
      margin-top: -8px;
    }

    span.bar {
      display: none;
    }
  }

  @media screen and (max-width: 639px) {
    h4 {
      margin-bottom: 0px;
    }

    .position {
      margin-top: 2px;
    }

    span {
    }

    a {
      margin-top: 8px;
    }
  }
}
</style>
